//NOTE: THIS IS CREATING A HUGE AMOUNT OF TECHNICAL DEBT, BUT WE WANT TO DO THIS QUICKLY
//SO WE'RE STARTING BY JUST COPYING AND PAST GEOGRAPHICFORM AND WE'LL REFACTOR LATER
import React from "react";
import { getBaseURL } from "./getQuery";
import { DropdownForm } from "./SelectionComponents";
import { fips } from "./StateFIPS";
import Coffee from "./Coffee";
import { Footer } from "./GeographicForm";

export class StateSelector extends React.Component {
	constructor(props) {
		super(props);
		this.handleStateChange = this.handleStateChange.bind(this);
	}

	handleStateChange(event) {
		document.location.href = getBaseURL() + "/state/" + event.target.value.replaceAll(" ", "_");
	}

	render() {
		return (
			<div className={"grid-static"}>
				<div className = "topnav">
					<a href="#top"><img className = "banner-logo" src="/maplogo.png" alt="Technology Policy Institute Logo"/></a>
					<a id = "title" href="https://techpolicyinstitute.org/"><img className = "banner-logo" src="/tpilogo.png" alt="Technology Policy Institute Logo"/></a>
				</div>
				<div className={"stateSelector"}>
					<h1 className={"state-selector-program-title"}>The State of Broadband - Broadband Maps For The States</h1>
					<h1 className={"state-selector-title"}>Select Your State</h1>
					<div className={"state-selector"}><DropdownForm className={"state-slector"} id={"state-change"} value={""} data={[{"name": " ", "code": "00"}, ...fips]} onChangeFunction={this.handleStateChange} style={{"text-align-last":"center" }}/></div>
				</div>
				<div className="buyMeACoffee"><Coffee /></div>
				<Footer />
			</div>
		);
	}
}
